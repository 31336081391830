/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-02 16:22:39
 * @Module: 搜索列表
 */
 <template>
  <div class="seachList">
    <div style="height:52px;"></div>
    <div class="seachList-box">
      <div class="seachList-box-content">
        <div class="seachList-box-content-search">
          <div class="seachList-box-content-search-l">
            <el-input v-model="keywords"
                      placeholder="输入搜索内容"></el-input>
          </div>
          <div class="seachList-box-content-search-r hover"
               @click="_get_list">搜索</div>
        </div>

        <!-- <div class="seachList-box-content-user">
          <div :style="all?'height:auto':'height:450px'"
               style="overflow: hidden;">
            <div class="seachList-box-content-user-item"
                 v-for="index in 10"
                 :key="index">
              <div class="seachList-box-content-user-item-l">
                <el-avatar :size="60"></el-avatar>
                <div class="seachList-box-content-user-item-l-info">
                  <div class="seachList-box-content-user-item-l-info-t">
                    头条红人
                  </div>
                  <div class="seachList-box-content-user-item-l-info-b">
                    这个人很懒，什么都没留下。
                  </div>
                </div>
              </div>
              <div class="seachList-box-content-user-item-r">
                +关注
              </div>
            </div>
          </div>
          <div class="seachList-box-content-user-btn hover"
               @click="all=!all">
            <span>
              <span>{{ all?'收起':'全部'}}</span>
              <i :class="all?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
            </span>
          </div>
        </div> -->

        <div class="seachList-box-content-item">
          <item v-for="(item,index) in list"
                :key="item.uuid"
                :data="item"
                :itemindex="index"
                @footer-click="onclick"
                @header-click="header_click" />
        </div>
        <div class="homepage-pagination"
             v-show="pagination.total">
          <el-pagination background
                         hide-on-single-page
                         layout="prev, pager, next,jumper"
                         :current-page="pagination.page"
                         @current-change="currentChange"
                         :page-size="pagination.pageSize"
                         :total="pagination.total">
          </el-pagination>
        </div>
        <transpond-dialog :visible="transpondDialogVisible"
                          :data="selectData"
                          @close="transpond_dialog_change" />
      </div>
    </div>
  </div>
</template>
 <script>
import item from "./item"
import momentListMixins from "@/views/homepage/momentListMixins"
export default {
  components: {
    item
  },
  mixins: [momentListMixins],
  data () {
    return {
      keywords: '',
      all: false,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      }
    };
  },
  mounted () {
    this.keywords = this.$route.query.keyword
    this._get_list()
  },
  methods: {
    async _get_list (page = 1) {
      this.loading = true
      const { status, info, data, meta } = await this.$api.momentListApi({ page, search_text: this.keywords })
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        sessionStorage.setItem("page", meta.pagination.current_page);
      } else {
        this.$message.error(info)
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.seachList {
  background: #f7f7f7;
  min-height: 100vh;
}
.seachList-box {
  width: 1240px;
  margin: 0 auto;
  &-content {
    width: 884px;
    min-height: 100px;
    padding-top: 16px;
    &-search {
      display: flex;
      border: 1px solid #df2a29;
      border-radius: 4px;
      // box-sizing: content-box;
      align-items: center;
      height: 40px;
      &-l {
        flex: 1;
        & /deep/ .el-input__inner {
          border: none;
          height: 38px;
        }
      }
      &-r {
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #df2a29;
        color: #fff;
      }
    }
    &-user {
      border-radius: 4px;
      margin-top: 16px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      &-item {
        height: 90px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        &-l {
          flex: 1;
          display: flex;
          align-items: center;
          &-info {
            margin-left: 13px;
            &-t {
              color: #333;
              font-size: 20px;
            }
            &-b {
              font-size: 14px;
              color: #999;
              margin-top: 4px;
            }
          }
        }
        &-r {
          width: 100px;
          line-height: 40px;
          text-align: center;
          background: #df2a29;
          border-radius: 4px;
          color: #fff;
          font-size: 16px;
        }
      }
      &-btn {
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #666;
      }
    }
    &-item {
      margin-top: 16px;
    }
  }
}
.homepage-pagination {
  display: flex;
  justify-content: center;
  background: #fff;
  margin-top: -10px;
  padding-top: 40px;
  padding-bottom: 40px;
}
</style>